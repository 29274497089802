<script setup lang='ts'>
import type { FooterProps } from '~/utils/footer'
import { ContentGrid } from '#components'
import { KsIcon } from '@aschehoug/kloss'
import Author from './footer/Author.vue'
import Mobile from './footer/Mobile.vue'
import NewsInput from './footer/NewsInput.vue'

const props = defineProps<FooterProps>()

const meta = useFooterMeta(props)

const { theme } = useTheme()
</script>

<template>
  <footer :class="footerVariants.base({ theme })">
    <component :is="meta.wide ? ContentGrid : 'div'" :class="{ 'max-w-9xl mx-auto px-m lg:px-xl': !meta.wide }">
      <div :class="meta.wide ? 'full-width' : 'max-w-screen-xl mx-auto'">
        <div class="flex flex-col gap-xl md:hidden">
          <Mobile />
        </div>
        <div class="hidden md:grid gap-xl grid-cols-2 md:grid-cols-3 xl:grid-cols-4 grid-rows-2 lg:grid-rows-1 grid-col-flow">
          <div class="flex flex-col gap-m md:gap-l col-span-1">
            <h5>Kontakt</h5>
            <div class="flex flex-col gap-2xs">
              <a href="mailto:post@juridika.no" class="text-small">post@juridika.no</a>
              <PhoneNumber value="+4724147500" class="text-small" hide-country-code />
              <span class="text-small">Postboks 508 Sentrum</span>
              <span class="text-small">0105 Oslo</span>
            </div>
          </div>
          <div class="flex flex-col gap-m lg:gap-l col-span-1">
            <h5>Informasjon</h5>
            <div class="flex flex-col gap-2xs">
              <a :href="BECOME_AN_AUTHOR_URL" class="text-small">Bli forfatter</a>
              <NuxtLink to="/brukeravtale" aria-label="Lenke til brukeravtale side" class="text-small">
                Brukervilkår
              </NuxtLink>
              <NuxtLink to="/personvern" aria-label="Lenke til personvern side" class="text-small">
                Personvern
              </NuxtLink>
              <NuxtLink to="/bruk-av-informasjonskapsler" aria-label="Lenke til informasjonskapsler side" class="text-small">
                Hjelpesider
              </NuxtLink>
            </div>
          </div>
          <div class="flex flex-col gap-m md:gap-l col-span-1">
            <h5> En tjeneste fra</h5>
            <Author />
          </div>
          <div class="flex flex-col gap-2xs xl:gap-l col-span-1 md:col-span-4 xl:col-span-1">
            <h5 class="text-caption font-normal xl:title-s">
              Meld deg på vårt nyhetsbrev
            </h5>
            <div class="grid grid-cols-3 grid-rows-1 w-full xl:gap-m xl:flex xl:flex-col">
              <div class="col-span-2 max-w-sm">
                <NewsInput />
              </div>
              <div class="col-span-1 flex gap-s justify-start">
                <NuxtLink v-for="(social, key) in LAYOUT_SOCIAL_ICONS" :key="key" class="no-underline flex gap-s items-center" :href="social.url" target="_blank" :aria-label="social.label">
                  <KsIcon :icon="social.icon" />
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </component>
  </footer>
</template>
